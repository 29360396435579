import React from "react"
import { Link } from 'gatsby';
import Layout from '../../components/Layout';

const Highlight = ({ pageContext: { publicHighlight } }) => (
  <Layout>
    <div style={{ width: 960, margin: '4rem auto' }}>
      <h1 >{publicHighlight._source.url}</h1>
      <ul>
        <li>{publicHighlight._source.url}</li>
        <li data-typesense-field="title">{JSON.stringify(publicHighlight._source.title)}</li>
        <li>{publicHighlight._source.created_at}</li>
        <li>{publicHighlight._source.tags_details ? publicHighlight._source.tags_details.map((tag) => { return tag.name + ', ' }) : ""}</li>
      </ul>
      <Link to="/">Back</Link>
    </div>
  </Layout>
);

export default Highlight;
